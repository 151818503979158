import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class UmbrellaExcessInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Umbrella & Excess Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Umbrella and Excess Insurance policies are designed to complement your base policy by offering additional coverage for the most expensive claims. Contact us for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Person signing umbrella insurance policy" />
        <Callout
          title="Umbrella & Excess Insurance"
          content="Umbrella and Excess Insurance policies are designed to complement your base policy by offering additional coverage for the most expensive claims. Whether you're exposed to a liability from your operations, legal trends or liability from a vendor or customer, this will help address these problems to keep your business in Louisiana running at an optimal level."
        />
        <div className="site-body page-content">
          <h2>What Umbrella and Excess Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Revenue.</strong> This is the income your business would have earned during the closure period if it were operating normally.</li>
          <li><strong>Rent or lease payments.</strong> Many leases require the renter to continue making payments even if the premises become unusable. Most also exclude renters from any insurance a landlord has, so you need to rely on your own coverage.</li>
          <li><strong>Relocation.</strong> This is the expense of moving your business to a temporary location and may include both moving and rent costs.</li>
          <li><strong>Employee wages.</strong> This is essential if you don’t want to lose employees while you’re shut down. Your coverage can help you make payroll when you’re unable to operate.</li>
          <li><strong>Taxes.</strong> The taxman doesn’t stop collecting, even when disaster hits. This can help you pay your taxes on time.</li>
          <li><strong>Loan payments.</strong> Loan payments are often due monthly, so your coverage can help you make those payments even when you can’t generate income.</li>
          <li><strong>Losses caused by covered damage that prevents access to a building.</strong> For example, this coverage can help out when the government implements a citywide curfew or other restrictions that keep people away from your business. This
            is usually called “civil authority coverage” or “loss of ingress or egress.”</li>
        </ul>
          </p>
          <DidYouKnow
            fact="There is no standardized form for Commercial Umbrella policies, which means that each insurance company creates their own umbrella policy form and decides the specifics of it."
            source=""
          />
          <h2>What Umbrella and Excess Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Malpractice lawsuits.</li>
          <li>Professional liability lawsuits.</li>
          <li>Employee discrimination lawsuits.</li>
          <li>Property damage.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an Umbrella and Excess Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default UmbrellaExcessInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "umbrella-excess-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-7.jpg" }) {
      ...mainBg
    }
  }
`;
